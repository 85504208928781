import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import Footer from "../components/Footer"
import { Row, Col, Empty } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import styled from "@emotion/styled"

const RowEmptyWrapper = styled(Row)`
  margin: 120px 0;
`
const IntroContainer = styled.div`
  margin: 60px 0;
  .introWrapper {
    font-family: "Kanit", sans-serif;
    margin: 0 auto;
    text-align: center;
    span {
      font-weight: 300;
      font-size: 14px;
      color: #7497b2;
    }
    h2 {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    margin: 80px 0;
  }
`
const QueryWrapper = styled.div`
  margin: 60px 0 80px 0;

  @media only screen and (min-width: 768px) {
    margin: 80px 0 100px 0;
  }
`
const CardWrapper = styled.div`
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  height: 100%;
  min-height: 100%;
  transition: all 0.75s ease-out;
  &:hover {
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.2);
    .contentWrapper {
      left: 0;
      bottom: 0;
      transform: translateY(-10%);
      border-radius: 10px;
      .excerpt {
        display: block;
      }
    }
  }
  .featuredWrapper {
    display: flex;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    z-index: 1;
    a {
      display: inline-flex;
    }
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .contentWrapper {
    padding: 35px 25px 25px 25px;
    font-family: "Kanit", sans-serif;
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    transition: all 0.05s ease-in;
    z-index: 2;
    .date {
      font-weight: 300;
      font-size: 16px;
      color: #646464;
      margin-bottom: 0;
    }
    h3 {
      font-weight: 400;
      font-size: 24px;
      color: #181818;
      margin-bottom: 10px;
    }
    .excerpt {
      display: none;
      font-weight: 400;
      font-size: 16px;
      color: #646464;
    }
  }
`
const PaginateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`

const ProjectsPage = ({ data, pageContext, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields, projectsPosts } = data
  const { seoTitle, seoDescription, coverImage } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)

  const posts = projectsPosts.edges
  const { currentPage, numProjects } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numProjects
  let prevPage = `/projects/` + (currentPage - 1) + `/`
  if (Number(currentPage) < 3) {
    prevPage = `/projects/`
  }
  const nextPage = `/projects/` + (currentPage + 1) + `/`

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "rootMenu3Sub2" })}
        secondLink="/about-us/"
        secondText={intl.formatMessage({ id: "rootMenu3" })}
      />
      <IntroContainer>
        <div className="gb-container">
          <Row justify="center">
            <Col span={24}>
              <div className="introWrapper">
                <span>{intl.formatMessage({ id: "rootMenu3" })}</span>
                <h2>{intl.formatMessage({ id: "rootMenu3Sub2" })}</h2>
              </div>
            </Col>
          </Row>
        </div>
      </IntroContainer>
      {data ? (
        <QueryWrapper>
          <div className="gb-container">
            <Row gutter={[16, 16]}>
              {posts &&
                posts.map((post, index) => {
                  const getFeatured = getImage(post.node.featured)

                  return (
                    <Col key={index} xs={24} sm={12} lg={8}>
                      <CardWrapper>
                        <div className="featuredWrapper">
                          <a href={post.node.file.file.url} target="_blank" rel="noreferrer">
                            <GatsbyImage image={getFeatured} alt="" />
                          </a>
                        </div>
                        <div className="contentWrapper">
                          <p className="date">{post.node.createdAt}</p>
                          <a href={post.node.file.file.url} target="_blank" rel="noreferrer">
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: post.node.title,
                              }}
                            />
                          </a>
                          {/*<p
                            className="excerpt"
                            dangerouslySetInnerHTML={{
                              __html: post.node.excerpt.excerpt,
                            }}
                          />
                          */}
                        </div>
                      </CardWrapper>
                    </Col>
                  )
                })}
            </Row>
            {numProjects > 1 && (
              <Row>
                <Col span={24}>
                  <PaginateWrapper>
                    <ul className="ant-pagination">
                      {isFirst ? (
                        <li
                          key="prev"
                          className="ant-pagination-prev ant-pagination-disabled"
                        >
                          <button
                            className="ant-pagination-item-link"
                            type="button"
                            tabIndex="-1"
                            disabled
                          >
                            <LeftOutlined />
                          </button>
                        </li>
                      ) : (
                        <li key="prev" className="ant-pagination-item">
                          <Link to={prevPage} rel="prev">
                            <LeftOutlined />
                          </Link>
                        </li>
                      )}
                      {Array.from({ length: numProjects }, (_, i) => (
                        <li
                          key={`pagination-number${i + 1}`}
                          className={
                            i + 1 === currentPage
                              ? "ant-pagination-item ant-pagination-item-active"
                              : "ant-pagination-item"
                          }
                        >
                          <Link
                            to={`/projects/${i < 1 ? "" : i + 1 + "/"}`}
                            className={i + 1 === currentPage ? `active` : ``}
                          >
                            {i + 1}
                          </Link>
                        </li>
                      ))}
                      {isLast ? (
                        <li
                          key="next"
                          className="ant-pagination-next ant-pagination-disabled"
                        >
                          <button
                            className="ant-pagination-item-link"
                            type="button"
                            tabIndex="-1"
                            disabled
                          >
                            <RightOutlined />
                          </button>
                        </li>
                      ) : (
                        <li key="next" className="ant-pagination-item">
                          <Link to={nextPage} rel="next">
                            <RightOutlined />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </PaginateWrapper>
                </Col>
              </Row>
            )}
          </div>
        </QueryWrapper>
      ) : (
        <RowEmptyWrapper>
          <Col span={24}>
            <Empty />
          </Col>
        </RowEmptyWrapper>
      )}
      <Footer />
    </Layout>
  )
}

export default injectIntl(ProjectsPage)

export const query = graphql`
  query ProjectsPostsQuery(
    $skip: Int!
    $limit: Int!
    $locale: String
    $language: String
  ) {
    pageFields: allContentfulProjectsPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    projectsPosts: allContentfulProjectsPosts(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: createdAt }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          title
          createdAt(formatString: "DD MMM YYYY", locale: $language)
          featured {
            gatsbyImageData(width: 510, height: 401, placeholder: BLURRED)
          }
          file {
            file {
              url
            }
          }
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`
